<template>
  <div class="result" @click="visitResult">
    <div class="result__img">
      <!--<img :src="item.featured_image || '/images/default-photo.png'" />-->
      <img v-lazy="item.featured_image || '/images/default-photo.png'" />
    </div>
    <div class="result__content">
      <h3>{{itemTitle}}</h3>
      <h5 v-if="type !== 'history'">{{itemAddress}}</h5>
      <div v-plaintext>
        <p v-html="itemDescription"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ResultItem',
  props: [
    'item',
    'type'
  ],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language'
    }),
    itemTitle () {
      if (this.item.title) {
        return this.item.title[this.language] || this.item.title[Object.keys(this.item.title)[0]]
      }

      return this.item.name[this.language] || this.item.name[Object.keys(this.item.name)[0]]
    },
    itemDescription () {
      return this.item.description[this.language] || this.item.description[Object.keys(this.item.description)[0]]
    },
    itemAddress () {
      return this.item.address[this.language] || this.item.address[Object.keys(this.item.address)[0]]
    }
  },
  mounted () {
  },
  methods: {
    visitResult () {
      if (this.type === 'history') {
        this.$router.push(`/histories/${this.item.id}`)
      } else {
        this.$router.push(`/places/${this.item.id}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
