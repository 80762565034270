<template>
  <div class="results">
    <result-item v-for="item in items" :key="item.id" :type="type" :item="item"/>
    <p v-if="!items.length" class="results__no">{{$t('global.no_result')}}</p>
  </div>
</template>

<script>
import ResultItem from './resultItem'

export default {
  name: 'SearchResults',
  components: { ResultItem },
  props: [
    'items',
    'type'
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
