<template>
  <div class="places">
    <top-header/>
    <div class="panel">
      <div class="panel__left">
        <search-bar @search="search" :location="location" :placeHolderText="$t('global.search_place')"/>
        <div class="panel__content">
          <search-results :items="results"/>
        </div>
        <bottom-menu/>
      </div>
      <div class="panel__right" :class="{ 'has-filters': hasFilters }">
        <search-map :results="results" @locationSet="onLocationSet"/>
      </div>
    </div>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import SearchMap from '@/components/map/map'
import BottomMenu from '@/components/bottomMenu/bottomMenu'
import SearchBar from '@/components/searchBar/searchBar'
import SearchResults from '@/components/results/results'
import TopHeader from '@/components/header/header'
import { Places } from '@/api/api'
import { mapGetters } from 'vuex'
export default {
  name: 'Places',
  components: { Loading, TopHeader, SearchMap, SearchBar, BottomMenu, SearchResults },
  data () {
    return {
      isLoading: true,
      loadingStatus: 0,
      results: [],
      filters: {}
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    }),
    hasFilters () {
      return this.filters.searchTerm || this.filters.districts
    },
    location () {
      return {
        lat: this.filters.lat,
        long: this.filters.long
      }
    }
  },
  mounted () {
  },
  methods: {
    async onLocationSet ({ lat, long }) {
      this.filters = {
        ...this.filters,
        lat,
        long
      }

      if (this.isLoading) {
        return
      }

      this.isLoading = true

      try {
        await this.loadData()
      } catch (e) {
        this.$toast.error(this.$t('places.load_failed2'))
      } finally {
        this.isLoading = false
      }
    },
    async search (data) {
      this.filters = {
        ...this.filters,
        ...data.filters
      }

      this.isLoading = true

      try {
        await this.loadData()
      } catch (e) {
        this.$toast.error(this.$t('places.load_failed2'))
      } finally {
        this.isLoading = false
      }
    },
    async loadData () {
      if (!this.filters.lat || !this.filters.long) {
        return
      }

      const resp = await Places.searchMap(
        this.filters.lat,
        this.filters.long,
        this.filters.searchTerm,
        this.filters.districts,
        this.filters.tagId,
        this.filters.tags,
        this.filters.forms,
        this.filters.nationalEducation
      )

      this.results = resp.data
    }
  }
}
</script>

<style lang="scss">
  @import "style";
</style>
